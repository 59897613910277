import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// console.log(Sentry , 'sentry ->>>>>')
async function lazyLoadSentryIntegrations() {
    // don't load on server
    if (!process.client) return;

    // const { Replay } = await import("@sentry/vue");
    // Sentry.addIntegration(new Replay({
    //     maskAllText: false,
    //     blockAllMedia: false,
    // }));
}

function getSentryIntegrations() {
    // don't load on server
    if (!process.client) return [];

    const router = useRouter();

    // const browserTracing = new Sentry.BrowserTracing({
    //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    // });

    // return [browserTracing];
}


// const router = useRouter()

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp;

        const config = useRuntimeConfig();
        // const router = useRouter();
        // console.log(config , 'config->>>')
        Sentry.init({
            environment: config.public.SENTRY_ENV,
            app: vueApp,
            dsn: config.public.SENTRY_DSN_PUBLIC,
            // integrations: getSentryIntegrations(),

          integrations: [Sentry.browserTracingIntegration()],

  // integrations: [Sentry.BrowserTracing({})],

    // integrations: [
    //   new Integrations.BrowserTracing({
    //     routingInstrumentation: Sentry.vueRouterInstrumentation(this.$router),
    //     tracingOrigins: ["localhost", /^\//],
    //   }),
    // ],
    // integrations: [
    //   Sentry.browserTracingIntegration(), 
    //   // Sentry.replayIntegration()
    // ],

// //   integrations: [
// //     Sentry.browserTracingIntegration(),
// //     Sentry.replayIntegration(),
// //   ],

            // integrations: [

            //    new Sentry.BrowserTracing({
            //      // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            //      // tracingOrigins: ["localhost", /^\//],
            //    })
            //    ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            // tracesSampleRate: 1,
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["http://localhost:3000", "https://dev.diggajcoder.com" ,/^https:\/\/yourserver\.io\/api/],

            // This sets the sample rate. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE as number,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
        });

        // Lazy-load the replay integration to reduce bundle size
        // lazyLoadSentryIntegrations();

    }
});


// // import * as Sentry from "@sentry/vue";
// // import { Integrations } from "@sentry/tracing";

// // export default ({ app, router, store }) => {
// //   // Sentry.init({
// //   //   Vue: app,
// //   //   dsn: process.env.SENTRY_DSN,
// //   //   environment: process.env.SENTRY_ENV,
// //   //   integrations: [
// //   //     new Integrations.BrowserTracing({
// //   //       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
// //   //       tracingOrigins: ["localhost", /^\//],
// //   //     }),
// //   //   ],
// //   //   tracesSampleRate: 1.0,
// //   //   // Session Replay
// //   //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// //   //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.    
// //   // });

// // Sentry.init({
// //   app,
// //   dsn: "https://64dbed401688e8b6d0798a42768610ea@o4506906554335232.ingest.us.sentry.io/4507577162334208",
// //   environment: process.env.SENTRY_ENV,  
// //   integrations: [
// //     Sentry.browserTracingIntegration(),
// //     Sentry.replayIntegration(),
// //   ],
// //   // Performance Monitoring
// //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
// //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/ , /^\//],
// //   // Session Replay
// //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// // });

// // };
// import * as Sentry from '@sentry/vue'

// export default defineNuxtPlugin((nuxtApp) => {
//   const router = useRouter()
//   const { public: { sentry } } = useRuntimeConfig()

//   if (!sentry.dsn) {
//     return
//   }

//   Sentry.init({
//     app: nuxtApp.vueApp,
//     dsn: sentry.dsn,
//     environment: sentry.environment,
//     integrations: [
//       Sentry.browserTracingIntegration({ router }), 
//       Sentry.replayIntegration()
//     ],

//     // Configure this whole part as you need it!

//     tracesSampleRate: 0.2, // Change in prod

//     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['localhost', 'https://your-server.com'],

//     replaysSessionSampleRate: 1.0, // Change in prod
//     replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
//   })
// })