import { default as AboutjsonG2suiFYkYgMeta } from "/vercel/path0/pages/Aboutjson.vue?macro=true";
import { default as Blog_45Full_45ViewlxZP1DOjoxMeta } from "/vercel/path0/pages/Blog-Full-View.vue?macro=true";
import { default as BlogPageDxKyHesGGuMeta } from "/vercel/path0/pages/BlogPage.vue?macro=true";
import { default as BlogPagePreviewiG5NyTA7bmMeta } from "/vercel/path0/pages/BlogPagePreview.vue?macro=true";
import { default as BootcampgUSESVqSsJMeta } from "/vercel/path0/pages/Bootcamp.vue?macro=true";
import { default as CommunityzfPy3TP89SMeta } from "/vercel/path0/pages/Community.vue?macro=true";
import { default as ContactUs1egTUxu8n1Meta } from "/vercel/path0/pages/ContactUs.vue?macro=true";
import { default as _91courseName_93VwlaI1Ld97Meta } from "/vercel/path0/pages/Course/[courseName].vue?macro=true";
import { default as CourseComparisonJ0k5eeJI8sMeta } from "/vercel/path0/pages/CourseComparison.vue?macro=true";
import { default as Edit_Profile_Personal_Detailsgii2hQEDdQMeta } from "/vercel/path0/pages/Edit_Profile_Personal_Details.vue?macro=true";
import { default as FAQsUqENcKijw4Meta } from "/vercel/path0/pages/FAQs.vue?macro=true";
import { default as hiretech_46vuecwGLQI5EPPMeta } from "/vercel/path0/pages/hiretech.vue.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as InternshipJoiningFormrGBcgStZhsMeta } from "/vercel/path0/pages/InternshipJoiningForm.vue?macro=true";
import { default as IntershipP9CWro0t2aMeta } from "/vercel/path0/pages/Intership.vue?macro=true";
import { default as JobPage3ZqX2Hl11bMeta } from "/vercel/path0/pages/JobPage.vue?macro=true";
import { default as JobPortalHomehfCWNt1iL8Meta } from "/vercel/path0/pages/JobPortalHome.vue?macro=true";
import { default as JobPortalProfileVisitorAXl4W804neMeta } from "/vercel/path0/pages/JobPortalProfileVisitor.vue?macro=true";
import { default as JobprofilejobdetailsESLTf9TR6OMeta } from "/vercel/path0/pages/Jobprofilejobdetails.vue?macro=true";
import { default as NewBlogPoh3nsgZaFMeta } from "/vercel/path0/pages/NewBlog.vue?macro=true";
import { default as NotificationU7gMX6Ca9kMeta } from "/vercel/path0/pages/Notification.vue?macro=true";
import { default as policyNKohT57BqpMeta } from "/vercel/path0/pages/policy.vue?macro=true";
import { default as Profile_45Post_45about_45Headersmdbx7LnFBMeta } from "/vercel/path0/pages/Profile-Post-about-Header.vue?macro=true";
import { default as Profile7qP0ctJLGcMeta } from "/vercel/path0/pages/Profile.vue?macro=true";
import { default as ProfileTabSmjmCuJkN9Meta } from "/vercel/path0/pages/ProfileTab.vue?macro=true";
import { default as ReferAndEarn3RZBzfkEFmMeta } from "/vercel/path0/pages/ReferAndEarn.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as Service00bFGMsvTaMeta } from "/vercel/path0/pages/Service.vue?macro=true";
import { default as Servicespage2tMsDQKGw8Meta } from "/vercel/path0/pages/Servicespage.vue?macro=true";
import { default as SponsorshipV9Rarj4T1IMeta } from "/vercel/path0/pages/Sponsorship.vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
import { default as indexnYsurtLlaZMeta } from "/vercel/path0/pages/u/[username]/index.vue?macro=true";
import { default as _91PostId_93wx48X6T6pzMeta } from "/vercel/path0/pages/u/[username]/Post/[PostId].vue?macro=true";
import { default as UiUXTabs7PUDcKvHhkMeta } from "/vercel/path0/pages/UiUXTabs.vue?macro=true";
import { default as viewhxF8mRzn0DMeta } from "/vercel/path0/pages/view.vue?macro=true";
export default [
  {
    name: "Aboutjson",
    path: "/Aboutjson",
    component: () => import("/vercel/path0/pages/Aboutjson.vue").then(m => m.default || m)
  },
  {
    name: "Blog-Full-View",
    path: "/Blog-Full-View",
    component: () => import("/vercel/path0/pages/Blog-Full-View.vue").then(m => m.default || m)
  },
  {
    name: "BlogPage",
    path: "/BlogPage",
    component: () => import("/vercel/path0/pages/BlogPage.vue").then(m => m.default || m)
  },
  {
    name: "BlogPagePreview",
    path: "/BlogPagePreview",
    component: () => import("/vercel/path0/pages/BlogPagePreview.vue").then(m => m.default || m)
  },
  {
    name: "Bootcamp",
    path: "/Bootcamp",
    component: () => import("/vercel/path0/pages/Bootcamp.vue").then(m => m.default || m)
  },
  {
    name: "Community",
    path: "/Community",
    component: () => import("/vercel/path0/pages/Community.vue").then(m => m.default || m)
  },
  {
    name: "ContactUs",
    path: "/ContactUs",
    component: () => import("/vercel/path0/pages/ContactUs.vue").then(m => m.default || m)
  },
  {
    name: "Course-courseName",
    path: "/Course/:courseName()",
    component: () => import("/vercel/path0/pages/Course/[courseName].vue").then(m => m.default || m)
  },
  {
    name: "CourseComparison",
    path: "/CourseComparison",
    component: () => import("/vercel/path0/pages/CourseComparison.vue").then(m => m.default || m)
  },
  {
    name: "Edit_Profile_Personal_Details",
    path: "/Edit_Profile_Personal_Details",
    component: () => import("/vercel/path0/pages/Edit_Profile_Personal_Details.vue").then(m => m.default || m)
  },
  {
    name: "FAQs",
    path: "/FAQs",
    component: () => import("/vercel/path0/pages/FAQs.vue").then(m => m.default || m)
  },
  {
    name: "hiretech.vue",
    path: "/hiretech.vue",
    component: () => import("/vercel/path0/pages/hiretech.vue.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "InternshipJoiningForm",
    path: "/InternshipJoiningForm",
    component: () => import("/vercel/path0/pages/InternshipJoiningForm.vue").then(m => m.default || m)
  },
  {
    name: "Intership",
    path: "/Intership",
    component: () => import("/vercel/path0/pages/Intership.vue").then(m => m.default || m)
  },
  {
    name: "JobPage",
    path: "/JobPage",
    component: () => import("/vercel/path0/pages/JobPage.vue").then(m => m.default || m)
  },
  {
    name: "JobPortalHome",
    path: "/JobPortalHome",
    component: () => import("/vercel/path0/pages/JobPortalHome.vue").then(m => m.default || m)
  },
  {
    name: "JobPortalProfileVisitor",
    path: "/JobPortalProfileVisitor",
    component: () => import("/vercel/path0/pages/JobPortalProfileVisitor.vue").then(m => m.default || m)
  },
  {
    name: "Jobprofilejobdetails",
    path: "/Jobprofilejobdetails",
    component: () => import("/vercel/path0/pages/Jobprofilejobdetails.vue").then(m => m.default || m)
  },
  {
    name: "NewBlog",
    path: "/NewBlog",
    component: () => import("/vercel/path0/pages/NewBlog.vue").then(m => m.default || m)
  },
  {
    name: "Notification",
    path: "/Notification",
    component: () => import("/vercel/path0/pages/Notification.vue").then(m => m.default || m)
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/vercel/path0/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "Profile-Post-about-Header",
    path: "/Profile-Post-about-Header",
    component: () => import("/vercel/path0/pages/Profile-Post-about-Header.vue").then(m => m.default || m)
  },
  {
    name: "Profile",
    path: "/Profile",
    component: () => import("/vercel/path0/pages/Profile.vue").then(m => m.default || m)
  },
  {
    name: "ProfileTab",
    path: "/ProfileTab",
    component: () => import("/vercel/path0/pages/ProfileTab.vue").then(m => m.default || m)
  },
  {
    name: "ReferAndEarn",
    path: "/ReferAndEarn",
    component: () => import("/vercel/path0/pages/ReferAndEarn.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "Service",
    path: "/Service",
    component: () => import("/vercel/path0/pages/Service.vue").then(m => m.default || m)
  },
  {
    name: "Servicespage",
    path: "/Servicespage",
    component: () => import("/vercel/path0/pages/Servicespage.vue").then(m => m.default || m)
  },
  {
    name: "Sponsorship",
    path: "/Sponsorship",
    component: () => import("/vercel/path0/pages/Sponsorship.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/vercel/path0/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "u-username",
    path: "/u/:username()",
    component: () => import("/vercel/path0/pages/u/[username]/index.vue").then(m => m.default || m)
  },
  {
    name: "u-username-Post-PostId",
    path: "/u/:username()/Post/:PostId()",
    component: () => import("/vercel/path0/pages/u/[username]/Post/[PostId].vue").then(m => m.default || m)
  },
  {
    name: "UiUXTabs",
    path: "/UiUXTabs",
    component: () => import("/vercel/path0/pages/UiUXTabs.vue").then(m => m.default || m)
  },
  {
    name: "view",
    path: "/view",
    component: () => import("/vercel/path0/pages/view.vue").then(m => m.default || m)
  }
]